













import { Component, Vue, Model, Emit, Ref } from 'vue-property-decorator'
import {
  recaptchaKeyV2,
} from '@/recaptcha'
import VueRecaptcha from 'vue-recaptcha'

@Component({
  components: {
    VueRecaptcha,
  },
})
export default class Recaptcha extends Vue {
  @Ref() readonly recaptcha!: any

  @Model('change', { type: String }) readonly value!: string

  recaptchaKeyV2 = recaptchaKeyV2

  @Emit('change')
  onVerificationRecaptchaV2 (token: string): string {
    return token
  }

  @Emit('change')
  resetToken (): string {
    return ''
  }

  reset (): void {
    this.recaptcha.reset()
    this.resetToken()
  }
}
